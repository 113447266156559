import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Radio from "@material-ui/core/Radio";

const AnswerRadioSizes = {
	sm: 30,
	md: 36,
	lg: 42,
} as const;

interface AnswerRadioProps {
	c1: string;
	c2: string;
	c3: string;
	sizing: keyof typeof AnswerRadioSizes;
}

interface AnswerRadioStylesProps {
	c1: string;
	c2: string;
	c3: string;
	sizing: number;
}

const useStyles = makeStyles({
	root: {
		"&:hover": {
			backgroundColor: "transparent",
		},
	},
	icon: {
		border: (arg: AnswerRadioStylesProps) => `2px solid ${arg.c1}`,
		borderRadius: "50%",
		width: (arg: AnswerRadioStylesProps) => `${arg.sizing / 16}rem`,
		height: (arg: AnswerRadioStylesProps) => `${arg.sizing / 16}rem`,
		background: "transparent",
		"input:hover ~ &": {
			border: (arg: AnswerRadioStylesProps) => `2px solid ${arg.c1}`,
			"&:before": {
				background: (arg: AnswerRadioStylesProps) => arg.c3,
				borderRadius: "50%",
				display: "block",
				padding: (arg: AnswerRadioStylesProps) => `${arg.sizing / (16 * 2)}rem`,
				content: '""',
			},
		},
	},
	checkedIcon: {
		border: (arg: AnswerRadioStylesProps) => `2px solid ${arg.c1}`,
		borderRadius: "50%",
		position: "relative",
		"&:before": {
			background: (arg: AnswerRadioStylesProps) => arg.c2,
			border: (arg: AnswerRadioStylesProps) => `${arg.sizing / (16 * 6)}rem solid white`,
			borderRadius: "50%",
			display: "block",
			padding: (arg: AnswerRadioStylesProps) => `${arg.sizing / (16 * 3)}rem`,
			content: '""',
		},
		"input:hover ~ &": {
			"&:before": {
				border: (arg: AnswerRadioStylesProps) =>
					`${arg.sizing / (16 * 6)}rem solid ${arg.c3}`,
			},
		},
	},
});

const AnswerRadio = (props: AnswerRadioProps) => {
	const { c1, c2, c3 } = props;

	const classes = useStyles({
		c1,
		c2,
		c3,
		sizing: AnswerRadioSizes[props.sizing || "md"],
	});

	return (
		<Radio
			className={classes.root}
			disableRipple
			color='default'
			checkedIcon={<span className={classes.checkedIcon} />}
			icon={<span className={classes.icon} />}
			{...props}
		/>
	);
};

export { AnswerRadio, AnswerRadioSizes };
