import React, { PropsWithChildren } from "react";
import { QuestionWithAnswers } from "../QuestionWithAnswers";
import { Card } from "../../atoms/Card";
import { makeStyles } from "@material-ui/core/styles";
import { theme } from "../../theme";
import { Box } from "../../atoms/Box";
import clsx from "clsx";

const useStyles = makeStyles({
	root: {
		[theme.breakpoints.down("md")]: {
			padding: "1.5rem",
		},
		[theme.breakpoints.up("md")]: {
			padding: "2.75rem",
		},
		boxShadow: ({ active }: { active: boolean }) =>
			active ? "0 2px 15px 0 rgba(61, 64, 91, 0.1)" : "none",
		borderRadius: "0.75rem",
	},
});

interface QuizCardProps {
	active: boolean;
	onAnswerChoice: (value: number) => void;
	question: string;
	className?: string;
}

const QuestionCard = React.forwardRef<HTMLDivElement, PropsWithChildren<QuizCardProps>>(
	(props: QuizCardProps, ref) => {
		const { active, onAnswerChoice, question, className } = props;
		const styles = useStyles({ active });

		return (
			<div ref={ref}>
				<Card className={clsx(styles.root, className)} raised={false}>
					<Box
						display={"flex"}
						justifyContent={"center"}
						alignItems={"center"}
						flexDirection={"column"}
					>
						<QuestionWithAnswers onClick={onAnswerChoice} question={question} />
					</Box>
				</Card>
			</div>
		);
	}
);

export { QuestionCard };
