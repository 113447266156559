import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { SvgIcon } from "../../atoms/SvgIcon";
import { Colors, TColors } from "../../theme";

const useStyles = makeStyles({
	root: {
		width: "1rem",
		height: "100%",
		transform: ({ direction }: { direction: Direction }) =>
			direction === "left" ? "rotate(-180deg)" : "",
	},
});

type Direction = "left" | "right";

interface ArrowIconProps {
	direction: Direction;
	color?: TColors;
}

const ArrowIcon = (props: ArrowIconProps) => {
	const { direction, color } = props;

	const styles = useStyles({ direction });
	return (
		<SvgIcon className={styles.root} viewBox={"0 0 15 13"}>
			<g
				stroke={color || Colors.LightGrey}
				strokeWidth='2'
				fill='none'
				fillRule='evenodd'
				strokeLinecap='round'
			>
				<path d='M1 6.425h10M8.929 2.353l4.242 4.243M8.928 10.839l4.243-4.243' />
			</g>
		</SvgIcon>
	);
};

export { ArrowIcon };
