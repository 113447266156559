import React from "react";
import { Box } from "../../atoms/Box";
import { StepperItem, StepperVariant } from "../../atoms/StepperItem";
import { Counter } from "../../atoms/Counter";

interface StepperProps {
	steps: number;
	activeStepIndex: number;
	variant: StepperVariant;
	fillPreviousSteps?: boolean;
	showCounter?: boolean;
}

const Stepper = (props: StepperProps) => {
	const { steps, activeStepIndex, variant, fillPreviousSteps, showCounter } = props;

	const renderStep = (step, index) => {
		const isActive = fillPreviousSteps
			? index <= activeStepIndex
			: index === activeStepIndex;
		return <StepperItem key={index} variant={variant} active={isActive} />;
	};

	return (
		<Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
			<Box display={"flex"}>{Array(steps).fill(0).map(renderStep)}</Box>
			{showCounter ? (
				<Box marginTop={1}>
					<Counter steps={steps} activeStepIndex={activeStepIndex} />
				</Box>
			) : null}
		</Box>
	);
};

export { Stepper };
