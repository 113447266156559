import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import { Colors, QuestionOptionsColors, theme } from "../../theme";
import { Box } from "../../atoms/Box";
import { Typography } from "../../atoms/Typography";
import { AnswerRadio } from "../../atoms/AnswerRadio";
import { useMobile } from "../../../hooks/useMobile";

const useStyles = makeStyles({
	legend: {
		color: `${Colors.Text} !important`,
	},
	formControlLabel: {
		marginRight: 0,
		marginLeft: 0,
	},
	text: {
		fontWeight: 500,
		[theme.breakpoints.down("md")]: {
			fontSize: "0.75rem",
		},
		[theme.breakpoints.up("md")]: {
			padding: "0.875rem",
			fontSize: "0.875rem",
			width: "100%",
		},
	},
});

interface QuestionWithAnswersProps {
	question: string;
	onClick: (value: number) => void;
}

function QuestionWithAnswers(props: QuestionWithAnswersProps) {
	const { question, onClick } = props;
	const styles = useStyles();
	const { isMobile } = useMobile();

	const handleClick = (e) => onClick(Number(e.target.value));

	return (
		<FormControl component='fieldset'>
			<Box
				display={"flex"}
				flexDirection={"column"}
				alignItems={"center"}
				justifyContent={"center"}
			>
				<FormLabel component='legend' className={styles.legend}>
					<Box marginBottom={3}>
						<Typography variant={"h4"} component={"h2"} align={"center"}>
							{question}
						</Typography>
					</Box>
				</FormLabel>
				<RadioGroup aria-label={question} name={question}>
					<Box display={"flex"} alignItems={"center"}>
						{isMobile ? null : (
							<Box marginRight={3}>
								<Typography variant={"body2"} className={styles.text}>
									Zgadzam się
								</Typography>
							</Box>
						)}
						<FormControlLabel
							className={styles.formControlLabel}
							value={"5"}
							control={
								<AnswerRadio
									c1={QuestionOptionsColors.G1}
									c2={QuestionOptionsColors.G2}
									c3={QuestionOptionsColors.G3}
									sizing={"lg"}
								/>
							}
							onClick={handleClick}
							label=''
						/>
						<FormControlLabel
							className={styles.formControlLabel}
							value={"4"}
							control={
								<AnswerRadio
									c1={QuestionOptionsColors.G2}
									c2={QuestionOptionsColors.G3}
									c3={QuestionOptionsColors.G4}
									sizing={"md"}
								/>
							}
							onClick={handleClick}
							label=''
						/>
						<FormControlLabel
							className={styles.formControlLabel}
							value={"3"}
							control={
								<AnswerRadio
									c1={QuestionOptionsColors.GR1}
									c2={QuestionOptionsColors.GR2}
									c3={QuestionOptionsColors.GR3}
									sizing={"sm"}
								/>
							}
							onClick={handleClick}
							label=''
						/>
						<FormControlLabel
							className={styles.formControlLabel}
							value={"2"}
							control={
								<AnswerRadio
									c1={QuestionOptionsColors.R2}
									c2={QuestionOptionsColors.R3}
									c3={QuestionOptionsColors.R4}
									sizing={"md"}
								/>
							}
							onClick={handleClick}
							label=''
						/>
						<FormControlLabel
							className={styles.formControlLabel}
							value={"1"}
							control={
								<AnswerRadio
									c1={QuestionOptionsColors.R1}
									c2={QuestionOptionsColors.R2}
									c3={QuestionOptionsColors.R3}
									sizing={"lg"}
								/>
							}
							onClick={handleClick}
							label=''
						/>
						{isMobile ? null : (
							<Box marginLeft={3}>
								<Typography variant={"body2"} className={styles.text}>
									Nie zgadzam się
								</Typography>
							</Box>
						)}
					</Box>
					{isMobile ? (
						<Box
							display={"flex"}
							justifyContent={"space-between"}
							width={"100%"}
							marginTop={2}
						>
							<Typography variant={"body2"} className={styles.text}>
								Zgadzam się
							</Typography>
							<Box marginRight={1}>
								<Typography variant={"body2"} className={styles.text}>
									Nie zgadzam się
								</Typography>
							</Box>
						</Box>
					) : null}
				</RadioGroup>
			</Box>
		</FormControl>
	);
}

export { QuestionWithAnswers };
