import React from "react";
import { Box } from "../../atoms/Box";
import { Button } from "../../atoms/Button";
import { Grid } from "../../atoms/Grid";
import { ArrowIcon } from "../../icons/ArrowIcon";
import { Colors } from "../../theme";
import { makeStyles } from "@material-ui/core";

interface QuizPageButtonProps {
	pageNumber: number;
	totalPages: number;
	onClick: () => void;
}

const useStyles = makeStyles({
	nextPageButtonIcon: {
		display: "flex",
		alignItems: "center",
		justifyContent: "flex-end",
	},
	nextPageButton: {
		padding: "0.625rem 0",
	},
});

const QuizNavigationButton = (props: QuizPageButtonProps) => {
	const { pageNumber, totalPages, onClick } = props;
	const styles = useStyles();
	const isLastQuizPage = pageNumber === totalPages;

	return (
		<Box maxWidth={"14rem"} width={"100%"}>
			<Button fullWidth variant={"contained"} onClick={onClick}>
				<Grid container className={styles.nextPageButton}>
					{isLastQuizPage ? (
						<Grid item xs={12}>
							Pokaż wyniki
						</Grid>
					) : (
						<>
							<Grid item xs={4} />
							<Grid item xs={4}>
								Dalej
							</Grid>
							<Grid item xs={4} className={styles.nextPageButtonIcon}>
								<ArrowIcon direction={"right"} color={Colors.White} />
							</Grid>
						</>
					)}
				</Grid>
			</Button>
		</Box>
	);
};

export { QuizNavigationButton };
