import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core";
import { navigate } from "gatsby";
import { Container } from "../../atoms/Container";
import { Layout } from "../Layout";
import { QuestionCard } from "../../molecules/QuestionCard";
import { Typography } from "../../atoms/Typography";
import { Box } from "../../atoms/Box";
import { Stepper } from "../../molecules/Stepper";
import { QuizNavigationButton } from "../../molecules/QuizNavigationButton";
import { PersonalityType } from "../../../models/PersonalityType";
import { useQuizResult } from "../../../context/QuizResult";
import { useScroll } from "../../../hooks/useScroll";
import { Colors } from "../../theme";

export type QuizQuestion =
	| {
			id: number;
			question: string;
			personalityType: PersonalityType;
	  }
	| {
			id: 30;
			question: string;
			personalityType: null;
	  };

interface QuizPageTemplateProps {
	pageContext: {
		helperText: string;
		questions: Array<QuizQuestion>;
		pageNumber: number;
		totalPages: number;
	};
}

const useStyles = makeStyles({
	background: {
		background: Colors.Background,
	},
	questionCard: {
		marginBottom: "1.5rem",
	},
	container: {
		maxWidth: "42rem",
	},
});

// The props are injected by Gatsby during the build process.
const QuizPageTemplate = (props: QuizPageTemplateProps) => {
	const {
		pageContext: { helperText, questions, pageNumber, totalPages },
	} = props;
	const styles = useStyles();
	const { dispatch, state } = useQuizResult();
	const [executeScroll, scrollRef] = useScroll<HTMLDivElement>({ behavior: "smooth" });
	const [unanswered, setUnanswered] = useState(() =>
		questions.map((question) => question.id)
	);

	const pageLoad = () => {
		const firstNotActive = state.activeQuestion !== questions[0].id;
		if (firstNotActive) {
			dispatch({ type: "SET_ACTIVE", payload: { id: questions[0].id } });
		}
	};

	const saveAnswer = (answerValue: number, questionId: number) => {
		dispatch({ type: "SAVE", payload: { id: questionId, value: answerValue } });
		setUnanswered((prevState) => prevState.filter((el) => el !== questionId));
		executeScroll();
	};

	const onGoToNextPage = () => {
		const firstQuestionOnNextPage = pageNumber * questions.length + 1;
		const isLastQuizPage = pageNumber === totalPages;

		const hasUnanswered = unanswered.length;

		if (hasUnanswered) {
			dispatch({ type: "SET_ACTIVE", payload: { id: unanswered[0] } });
			executeScroll();
		} else {
			if (isLastQuizPage) {
				navigate(`/result`);
			} else {
				dispatch({ type: "SET_ACTIVE", payload: { id: firstQuestionOnNextPage } });
				navigate(`/${pageNumber + 1}`);
			}
		}
	};

	const renderQuestions = ({ question, id }: QuizQuestion) => {
		return (
			<QuestionCard
				ref={id === unanswered[0] ? scrollRef : null}
				active={id === unanswered[0]}
				key={id}
				question={question}
				onAnswerChoice={(answerValue) => saveAnswer(answerValue, id)}
				className={styles.questionCard}
			/>
		);
	};

	useEffect(pageLoad, []);

	return (
		<Layout className={styles.background}>
			<Container className={styles.container}>
				<main>
					<Box marginTop={4}>
						<Stepper
							steps={5}
							activeStepIndex={pageNumber - 1}
							variant={"rounded"}
							fillPreviousSteps={true}
							showCounter={true}
						/>
					</Box>
					<Box display={"flex"} justifyContent={"center"} marginTop={3} marginBottom={4}>
						<Typography variant={"body2"} align={"center"}>
							{helperText}
						</Typography>
					</Box>
					{questions.map(renderQuestions)}
					<Box
						display={"flex"}
						justifyContent={"center"}
						alignItems={"center"}
						marginY={6}
					>
						<QuizNavigationButton
							pageNumber={pageNumber}
							totalPages={totalPages}
							onClick={onGoToNextPage}
						/>
					</Box>
				</main>
			</Container>
		</Layout>
	);
};

export default QuizPageTemplate;
