import { Typography } from "../Typography";
import React from "react";

interface CounterProps {
	steps: number;
	activeStepIndex: number;
}

const Counter = (props: CounterProps) => {
	const { activeStepIndex, steps } = props;
	return (
		<Typography variant={"caption"}>
			{activeStepIndex + 1} / {steps}
		</Typography>
	);
};

export { Counter };
