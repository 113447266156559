import { makeStyles } from "@material-ui/core/styles";
import { Colors } from "../../theme";
import React from "react";

const useStyles = makeStyles({
	dot: {
		borderRadius: "50%",
		backgroundColor: (active) => (active ? Colors.Green : Colors.Grey),
		height: "10px",
		width: "10px",
		"&:not(:last-of-type)": {
			marginRight: "10px",
		},
	},
	rounded: {
		borderRadius: "10px",
		backgroundColor: (active) => (active ? Colors.Green : Colors.Grey),
		height: "5px",
		width: "30px",
		"&:not(:last-of-type)": {
			marginRight: "14px",
		},
	},
});

export type StepperVariant = "dot" | "rounded";

interface StepperItemProps {
	active: boolean;
	variant: StepperVariant;
}

const StepperItem = (props: StepperItemProps) => {
	const { active, variant } = props;
	const styles = useStyles(active);

	return <div className={variant === "dot" ? styles.dot : styles.rounded} />;
};

export { StepperItem };
